import React, { useState, useEffect } from 'react';
import Modal from 'antd/es/modal/Modal';
import { Button, Col, Row, Select, Input } from 'antd';
import { capitalize, capitalizeAll } from '../../utils/utils';
import Spinner from '../navigation/Spinner';
import { useTranslation } from 'react-i18next';
import { getIdToken } from 'firebase/auth';
import { getBooksessionByQuery } from '../../services/booksession.service';
import moment from 'moment';
import 'moment/locale/es';

const BookingTemplateCreate = (props) => {
  const [selectedTrainer, setSelectedTrainer] = useState('null');
  const [selectedSlots, setSelectedSlots] = useState(1);
  const [loading, setLoading] = useState(true);
  const [booksessions, setBooksessions] = useState(null);
  const [selectedBooksession, setSelectedBooksession] = useState('null');
  const [selectedName, setSelectedName] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [color, setColor] = useState('#000000');
  const [message, setMessage] = useState('');
  const { TextArea } = Input;
  const { t } = useTranslation();
  const { Option } = Select;
  const colors = [
    {
      name: capitalize(t('red')),
      value: '#f5222d'
    },
    {
      name: capitalize(t('orange')),
      value: '#fa8c16'
    },
    {
      name: capitalize(t('yellow')),
      value: '#fadb14'
    },
    {
      name: capitalize(t('green')),
      value: '#52c41a'
    },
    {
      name: capitalize(t('blue')),
      value: '#1890ff'
    },
    {
      name: capitalize(t('purple')),
      value: '#722ed1'
    },
    {
      name: capitalize(t('pink')),
      value: '#eb2f96'
    },
    {
      name: capitalize(t('cyan')),
      value: '#13c2c2'
    },
    {
      name: capitalize(t('black')),
      value: '#000000'
    },
    {
      name: capitalize(t('grey')),
      value: '#bfbfbf'
    }
  ];

  useEffect(() => {
    loadBookSessions();
  }, []);

  useEffect(() => {
    if (selectedBooksession && selectedBooksession !== 'null') {
      const booksession = booksessions.find(
        (booksession) => booksession._id === selectedBooksession
      );
      const trainer = props.trainers.find(
        (trainer) => trainer._id === booksession.trainer
      );
      setSelectedName(booksession.name);
      setSelectedDescription(booksession.description);
      setSelectedSlots(booksession.slots);
      setColor(booksession.color || '#000000');
      setSelectedTrainer(
        trainer.name +
          ' ' +
          trainer.surname +
          ' - ' +
          trainer.email +
          ' - ' +
          trainer._id
      );
    }
  }, [selectedBooksession]);

  const checkFields = () => {
    if (selectedName === '') {
      setMessage('Es necesario un nombre');
      return false;
    }
    if (selectedDescription === '') {
      setMessage('Es necesaria una descripción');
      return false;
    }
    if (selectedTrainer === 'null') {
      setMessage('Es necesario un entrenador');
      return false;
    }
    return true;
  };

  const loadBookSessions = async () => {
    const { status, data } = await getBooksessionByQuery(
      { center: props.center._id, isActive: true },
      props.user.token
    );
    if (status === 200) {
      setBooksessions(data.data);
      setLoading(false);
    }
  };

  const createNewBooking = (payload) => {
    props.handleAddBook(payload);
    return props.bookings.length + 1;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Modal
        title={capitalizeAll(t('bookingData'))}
        visible={props.bookingDataVisible}
        onOk={() => {
          props.setBookingDataVisible(false);
        }}
        onCancel={() => {
          props.setBookingDataVisible(false);
          setSelectedSlots(1);
          setSelectedName('');
          setSelectedDescription('');
          setSelectedTrainer('null');
          setSelectedBooksession('null');
          setColor('#000000');
          setMessage('');
        }}
        onPan={(p) => {}}
        centered
        // width={'100%'}
        footer={[
          <Button
            key='OK'
            onClick={() => {
              try {
                if (!checkFields()) {
                  return;
                }
                const newBook = createNewBooking({
                  id: props.bookings.length + 1,
                  center: props.center._id,
                  trainer: selectedTrainer.split(' - ')[2],
                  name: selectedName,
                  description: selectedDescription,
                  isGroup: selectedSlots > 1,
                  slots: selectedSlots,
                  color: color,
                  start: new Date(props.start),
                  end: new Date(props.end)
                });
                const title =
                  selectedTrainer.split(' - ')[0] +
                  ' - ' +
                  selectedTrainer.split(' - ')[1];
                props.handleBookSlot(
                  newBook,
                  title,
                  props.start,
                  props.end,
                  'lightgreen'
                );
                props.setBookingDataVisible(false);
                setSelectedSlots(1);
                setSelectedName('');
                setSelectedDescription('');
                setColor('#000000');
                setSelectedTrainer('null');
                setSelectedBooksession('null');
                setMessage('');
              } catch (error) {
                setSelectedSlots(1);
                setSelectedName('');
                setSelectedDescription('');
                setColor('#000000');
                setSelectedTrainer('null');
                setSelectedBooksession('null');
                setMessage('Error');
              }
            }}
          >
            OK
          </Button>
        ]}
      >
        <Row>
          {capitalize(t('center'))}: {props.center.name} <br />
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <Row>{capitalize(t('booksession'))}:</Row>
            <Row style={{ width: '100%' }}>
              <Select
                value={selectedBooksession}
                style={{ width: '100%' }}
                onChange={setSelectedBooksession}
              >
                <Option value='null'>{capitalize(t('booksession'))}</Option>
                {booksessions.map((booksession, key) => {
                  const trainer = props.trainers.find(
                    (t) => t._id === booksession.trainer
                  );
                  if (!trainer) return null;
                  return (
                    <Option key={key} value={booksession._id}>
                      {booksession.name +
                        ' - ' +
                        trainer.name +
                        ' ' +
                        trainer.surname}
                    </Option>
                  );
                })}
              </Select>
            </Row>
            <Row>{capitalize(t('name'))}:</Row>
            <Row style={{ width: '100%' }}>
              <Input
                type='text'
                style={{ width: '100%' }}
                value={selectedName}
                onChange={(e) => {
                  setSelectedName(e.target.value);
                }}
              />
            </Row>
            <Row>{capitalize(t('description'))}:</Row>
            <Row style={{ width: '100%' }}>
              <TextArea
                type='text'
                style={{ width: '100%' }}
                value={selectedDescription}
                onChange={(e) => {
                  setSelectedDescription(e.target.value);
                }}
              />
            </Row>
            <Row>{capitalize(t('userSlots'))}:</Row>
            <Row>
              <Select
                value={selectedSlots}
                style={{ width: '100%' }}
                onChange={setSelectedSlots}
              >
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
                <Option value={10}>10</Option>
                <Option value={11}>11</Option>
                <Option value={12}>12</Option>
                <Option value={13}>13</Option>
                <Option value={14}>14</Option>
                <Option value={15}>15</Option>
                <Option value={16}>16</Option>
                <Option value={17}>17</Option>
                <Option value={18}>18</Option>
                <Option value={19}>19</Option>
                <Option value={20}>20</Option>
                <Option value={21}>21</Option>
                <Option value={22}>22</Option>
                <Option value={23}>23</Option>
                <Option value={24}>24</Option>
                <Option value={25}>25</Option>
                <Option value={26}>26</Option>
                <Option value={27}>27</Option>
                <Option value={28}>28</Option>
                <Option value={29}>29</Option>
                <Option value={30}>30</Option>
                <Option value={31}>31</Option>
                <Option value={32}>32</Option>
                <Option value={33}>33</Option>
                <Option value={34}>34</Option>
                <Option value={35}>35</Option>
                <Option value={36}>36</Option>
                <Option value={37}>37</Option>
                <Option value={38}>38</Option>
                <Option value={39}>39</Option>
                <Option value={40}>40</Option>
                <Option value={41}>41</Option>
                <Option value={42}>42</Option>
                <Option value={43}>43</Option>
                <Option value={44}>44</Option>
                <Option value={45}>45</Option>
                <Option value={46}>46</Option>
                <Option value={47}>47</Option>
                <Option value={48}>48</Option>
                <Option value={49}>49</Option>
                <Option value={50}>50</Option>
              </Select>
            </Row>
            <Row>{capitalize(t('trainer'))}:</Row>
            <Row style={{ width: '100%' }}>
              <Select
                value={selectedTrainer}
                style={{ width: '100%' }}
                onChange={setSelectedTrainer}
              >
                <Option value='null'>{capitalize(t('trainer'))}</Option>
                {props.trainers.map((trn, key) => {
                  return (
                    <Option
                      key={key}
                      value={
                        trn.name +
                        ' ' +
                        trn.surname +
                        ' - ' +
                        trn.email +
                        ' - ' +
                        trn._id
                      }
                    >
                      {trn.name + ' ' + trn.surname}
                    </Option>
                  );
                })}
              </Select>
            </Row>
            <Row>{capitalize(t('idColor'))}:</Row>
            <Row style={{ width: '100%' }}>
              <Select
                value={color}
                style={{ width: '100%' }}
                defaultValue={color}
                onChange={setColor}
              >
                {colors.map((color, index) => (
                  <Select.Option
                    key={index}
                    value={color.value}
                    style={{ color: color.value }}
                  >
                    {color.name}
                  </Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>
        <Row>
          {capitalize(t('startDate'))}: {moment(props.start).format('HH:mm')}
        </Row>
        <Row>
          {capitalize(t('endDate'))}: {moment(props.end).format('HH:mm')}
        </Row>
        <Row>
          <span style={{ color: 'red' }}>{message}</span>
        </Row>
      </Modal>
    </>
  );
};

export default BookingTemplateCreate;
