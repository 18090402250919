import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, message, Popconfirm, Select } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getCenterByQuery } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { createBooksession, getBooksessionById, patchBooksession } from '../../../services/booksession.service';
import { getAccountByQuery } from '../../../services/user.service';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateBooksession = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [booksession, setBooksession] = useState(null);
  const [trainers, setTrainers] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { TextArea } = Input;
  const { Option } = Select;
  const { t } = useTranslation();
  const colors = [{
    name: capitalize(t('red')),
    value: '#f5222d'
  }, {
    name: capitalize(t('orange')),
    value: '#fa8c16'
  }, {
    name: capitalize(t('yellow')),
    value: '#fadb14'
  }, {
    name: capitalize(t('green')),
    value: '#52c41a'
  }, {
    name: capitalize(t('blue')),
    value: '#1890ff'
  }, {
    name: capitalize(t('purple')),
    value: '#722ed1'
  }, {
    name: capitalize(t('pink')),
    value: '#eb2f96'
  }, {
    name: capitalize(t('cyan')),
    value: '#13c2c2'
  }, {
    name: capitalize(t('black')),
    value: '#000000'
  }, {
    name: capitalize(t('grey')),
    value: '#bfbfbf'
  }];

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      setCenter(data.data[0]);
      await loadTrainers(data.data[0]._id);
      if (params.id) {
        await loadBooksession(params.id, user.token);
      }
      setLoading(false);
    }
  };

  const loadBooksession = async (id, token) => {
    const { status, data } = await getBooksessionById(id, token);
    if (status === 200) {
      setBooksession(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const loadTrainers = async (centerId) => {
    const { status, data } = await getAccountByQuery({ center: centerId, roles: 'trainer', isActive: true }, user.token);
    if (status === 200) {
      setTrainers(data.data);
    }
  };

  const fillForm = (booksession) => {
    form.setFieldsValue({
      name: booksession.name,
      description: booksession.description,
      slots: booksession.slots,
      trainer: booksession.trainer,
      color: booksession.color ? booksession.color : '#000000'
    });
  };

  const deactivateBooksession = async () => {
    if (booksession) {
      const { status } = await patchBooksession(booksession._id, { isActive: false }, user.token);
      if (status === 200) {
        navigate('/dashboard/booksessions');
        message.success(capitalize(t('booksessionDeleted')));
      }
    }
  };

  const createNewBooksession = async (data, token) => {
    const formData = {
      name: data.name,
      description: data.description,
      slots: data.slots,
      trainer: data.trainer,
      center: center._id,
      color: data.color
    };
    if (params.id) await patchBooksession(params.id, formData, token);
    else await createBooksession(formData, token);
    navigate('/dashboard/booksessions');
  };

  const correctClave = async e => {
    await createNewBooksession(e, user.token);
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {booksession ? <h1>{capitalize(t('editBooksession'))}</h1> : <h1>{capitalize(t('createBooksession'))}</h1>}
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={booksession ? booksession.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>
          {capitalize(t('description'))}:
        </div>
        <Form.Item
          name="description"
          rules={[{
            required: false,
            message: capitalize(t('description')) + ' ' + t('isRequired')
          }]}>
          <TextArea
            addonBefore={capitalize(t('description'))}
            rows={4}
            name="description"
            defaultValue={booksession ? booksession.description : ''}
            placeholder={capitalize(t('description'))}
          />
        </Form.Item>
        <Form.Item
          name="slots"
          rules={[{
            required: true,
            message: capitalize(t('userSlots')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('userSlots'))}
            name="slots"
            type="number"
            onWheel={(e) => e.target.blur()}
            defaultValue={booksession ? booksession.slots : ''}
            placeholder={capitalize(t('userSlots'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>
          {capitalize(t('trainer'))}:
        </div>
        <Form.Item
          name="trainer"
          rules={[
            {
              required: true,
              message: capitalize(t('trainer')) + ' ' + t('isRequired')
            }
          ]}>
          <Select defaultValue={booksession ? booksession.trainer : 'null'} >
            <Option value="null">{capitalizeAll(t('selectTrainer'))}</Option>
            {trainers.map((trn, key) => {
              return (
                <Option key={key} value={trn._id}>{trn.name} {trn.surname}</Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="color"
          rules={[{
            required: true,
            message: capitalize(t('idColor')) + ' ' + t('isRequired')
          }]}>
          <Select
            placeholder={capitalize(t('idColor'))}
            defaultValue={booksession && booksession.color ? booksession.color : '#000000'}
          >
            {colors.map((color, index) => (
              <Select.Option key={index} value={color.value} style={{ color: color.value }}>
                {color.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {booksession && (
            <Popconfirm
              title={capitalize(t('confirmDeleteBooksession'))}
              onConfirm={deactivateBooksession}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {booksession ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createBooksession'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateBooksession;
