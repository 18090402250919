import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getDeviceByQuery } from '../../../services/device.service';
import { getIdToken } from 'firebase/auth';
import { getCenterById } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { Button, Col, Row, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {
  BarChartOutlined,
  CopyOutlined,
  DesktopOutlined,
  DollarOutlined,
  LinkOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  getAccountByQuery,
  getLowCreditAccounts
} from '../../../services/user.service';
import {
  getProgramSessionByQuery,
  getProgramSessionCount
} from '../../../services/programsession.service';
import ResumeDashboard from '../../../components/cards/CardResumeDashboard';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { getTransactionByQuery } from '../../../services/transaction.service';

const DashboardOrganizerHome = (props) => {
  const { user } = useContext(Auth);
  const [devices, setDevices] = useState([]);
  const [center, setCenter] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [programSessions, setProgramSessions] = useState(0);
  const [activeSessions, setActiveSessions] = useState(0);
  const [lowCreditAccounts, setLowCreditAccounts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadDevices = async (centerId) => {
    const { status, data } = await getDeviceByQuery(
      { center: centerId, isActive: true },
      user.token
    );
    if (status === 200) {
      data.data.forEach((device) => {
        device.key = device._id;
      });
      setDevices(data.data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  const loadUsers = async (centerId) => {
    const { status, data } = await getAccountByQuery(
      { center: centerId, roles: 'user', isActive: true },
      user.token
    );
    if (status === 200) {
      setUsers(data.data);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterById(
      user.account.center,
      user.token
    );
    if (status === 200) {
      setCenter(data.data);
      await loadDevices(data.data._id);
      await loadUsers(data.data._id);
      await loadTransaction(data.data._id);
      await loadProgramSessions(data.data._id);
      await loadActiveProgramSessions(data.data._id);
      if (data.data.plan[2] === '1') {
        await loadLowCreditAccounts(data.data._id);
      }
    }
    setLoading(false);
  };

  const loadTransaction = async (centerId) => {
    const { status, data } = await getTransactionByQuery(
      { center: centerId, status: 'succeded' },
      user.token
    );
    if (status === 200) {
      setTransactions(data.data);
    }
  };

  const loadProgramSessions = async (centerId) => {
    const { status, data } = await getProgramSessionCount(
      { center: centerId },
      user.token
    );
    if (status === 200) {
      setProgramSessions(data.data);
    }
  };

  const loadActiveProgramSessions = async (centerId) => {
    const { status, data } = await getProgramSessionCount(
      { center: centerId, isActive: true },
      user.token
    );
    if (status === 200) {
      setActiveSessions(data.data);
    }
  };

  const loadLowCreditAccounts = async (centerId) => {
    const { status, data } = await getLowCreditAccounts(centerId, user.token);
    if (status === 200) {
      setLowCreditAccounts(data.data);
    }
  };

  const renderDevice = (device) => {
    const url = process.env.REACT_APP_URL;
    const link = `/device/${center._id}/${device.identifier}`;
    return (
      <Row style={{ marginBottom: 12, alignItems: 'center' }}>
        <Col xs={24} lg={12}>
          {device.name} [{device.identifier}]
        </Col>
        <Col xs={24} lg={12} style={{ textAlign: 'right' }}>
          <Link
            type='primary'
            style={{ marginRight: 5 }}
            to={link}
            target='_blank'
          >
            {capitalize(t('goToUrl'))}
          </Link>
          <CopyToClipboard
            text={url + link}
            onCopy={() => message.success(capitalizeAll(t('copyUrl')))}
          >
            <Button>
              <CopyOutlined />
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Row justify='center' style={{ gap: 15, marginBottom: 25 }}>
        {center?.plan[0] === '1' && (
          <>
            <ResumeDashboard
              navigate={navigate}
              link={'/dashboard/users'}
              title={capitalize(t('users'))}
              icon={<TeamOutlined />}
              value={users.length}
            />
          </>
        )}
        {center?.plan[1] === '1' && (
          <>
            <ResumeDashboard
              navigate={navigate}
              link={'/dashboard/devices'}
              title={capitalize(t('devices'))}
              icon={<DesktopOutlined />}
              value={devices.length}
            />
            <ResumeDashboard
              navigate={navigate}
              link={'/dashboard/sessions'}
              title={capitalizeAll(t('activeSessions'))}
              icon={<BarChartOutlined />}
              value={activeSessions + '/' + programSessions}
            />
          </>
        )}
      </Row>

      {center?.plan[1] === '1' && (
        <>
          <Row>
            <Col xs={24} md={12}>
              <Row style={{ marginBottom: 25 }}>
                <Link
                  style={{ border: '1px solid', padding: 5 }}
                  to={`/training/${center._id}`}
                  target={'_blank'}
                >
                  {t('mainScreen').toUpperCase()} <LinkOutlined />
                </Link>
              </Row>
              {center?.plan[2] === '1' && lowCreditAccounts.length !== 0 && (
                <div style={{ marginBottom: 25 }}>
                  <p>
                    <b>{t('lowCreditAccounts').toUpperCase()}</b>
                  </p>
                  {lowCreditAccounts.map((account) => (
                    <div key={account._id}>
                      ➢ {account.name} {account.surname} ({account.credits}{' '}
                      {t('credits')})
                    </div>
                  ))}
                </div>
              )}
              <p>
                <b>{t('devices').toUpperCase()}</b>
              </p>
              {devices.map((device) => renderDevice(device))}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DashboardOrganizerHome;
