import React from 'react';
import { Button, Col, Row, Tooltip } from 'antd';
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const BookingHourButton = (props) => {
  return (

    <Tooltip title={props.booking.description}>
      <Button style={{ width: '100%', backgroundColor: props.color, height: 80, border: '3px solid', borderColor: props.borderColor, borderRadius: '4px' }} onClick={() => {
        props.setSelectedBooking(props.booking);
        props.setBookingDataVisible(true);
      }} disabled={props.disabled}>
        <Row justify={'space-between'}>
          <Col span={12} style={{ textAlign: 'left' }}>
            <b>{props.text}</b>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <ClockCircleOutlined /> {moment(props.booking.start).format('HH:mm')} -{moment(props.booking.end).format('HH:mm')}
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'left' }}>
            {props.booking.name}
          </Col>
        </Row>
        <Row>
          <Col span={20} style={{ textAlign: 'left' }}>
            <UserOutlined /> {props.booking.trainer.name} {props.booking.trainer.surname}
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            {props.booking.isGroup ? props.booking.group.length : props.booking.account ? '1' : '0'}/{props.booking.slots}
          </Col>
        </Row>
      </Button>
    </Tooltip>
  );
};

export default BookingHourButton;
