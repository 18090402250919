/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import BookingCreate from '../../../components/modals/BookingCreate';
import moment from 'moment';
import { patchBooking } from '../../../services/booking.service';
import { getIdToken } from 'firebase/auth';
import BookingEdit from '../../../components/modals/BookingEdit';
import Spinner from '../../../components/navigation/Spinner';
import ButtonGroup from 'antd/es/button/button-group';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';
import { useMediaQuery } from 'react-responsive';
require('moment/locale/es.js');

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const BookingDay = (props) => {
  const [bookingDate, setBookingDate] = useState([]);
  const [bookingCreateVisible, setBookingCreateVisible] = useState(false);
  const [bookingEditVisible, setBookingEditVisible] = useState(false);
  const [startData, setStartData] = useState(new Date());
  const [bookingEdit, setBookingEdit] = useState();
  const [booking, setBooking] = useState();
  const [endData, setEndData] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { t } = useTranslation();
  const calendarRef = useRef();

  useEffect(() => {
    setLoading(true);
    const { bookings } = props;
    const bookingsArr = [];
    bookings.forEach(booking => {
      const { start, end } = booking;
      const startDate = new Date(start);
      const endDate = new Date(end);
      // let color = 'lightgreen';
      let color = '#ffffff';
      let user = '';
      // if ((booking.account && booking.account !== 'undefined')) {
      //   color = 'orange';
      //   user = '[' + booking.account.name + ' ' + booking.account.surname + '] - ';
      // }
      // if (booking.isGroup && booking.group?.length > 0) {
      //   color = 'orange';
      //   user = '[' + booking.group.length + '/' + booking.slots + '] - ';
      // }
      // if (booking.queue.length > 0) {
      //   color = 'orange';
      // }
      // if (booking.queue.length > 4) {
      //   color = 'red';
      // }
      if ((booking.account && booking.account !== 'undefined')) {
        color = booking.trainer.color ? booking.trainer.color : 'orange';
        user = '[' + booking.account.name + ' ' + booking.account.surname + '] - ';
      }
      if (booking.isGroup && booking.group?.length > 0) {
        color = booking.trainer.color ? booking.trainer.color : 'orange';
        user = '[' + booking.group.length + '/' + booking.slots + '] - ';
      }
      if (booking.queue.length > 0) {
        color = booking.trainer.color ? booking.trainer.color : 'orange';
      }
      if (booking.queue.length > 4) {
        color = booking.trainer.color ? booking.trainer.color : 'red';
      }
      bookingsArr.push({
        start: startDate, end: endDate, title: user + booking.name + ' - ' + booking.trainer.name + ' ' + booking.trainer.surname + ' - ' + booking.trainer.email, id: booking._id, color: color, borderColor: booking.trainer.color ? booking.trainer.color : 'black'
      });
      setBookingDate(bookingsArr);
    });
    setLoading(false);
  }, [props.bookings]);

  const updateBooking = async (bookingId, start, end) => {
    const { status, data } = await patchBooking(bookingId, { start, end }, props.user.token);

    if (status === 200) {

    }
  };

  const moveBook = useCallback(
    ({
      event,
      start,
      end,
      resourceId,
      isAllDay: droppedOnAllDaySlot = false
    }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }
      updateBooking(event.id, start, end);
      setBookingDate((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, resourceId, allDay }];
      });
    },
    [setBookingDate]
  );

  const resizeBook = useCallback(
    ({ event, start, end }) => {
      updateBooking(event.id, start, end);
      setBookingDate((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setBookingDate]
  );

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setStartData(start);
      setEndData(end);
      setBookingCreateVisible(true);
    }
    // [setStart, setEnd]
  );

  const handleEditBookSlot = (id, title, start, end) => {
    props.loadBooks(props.center._id);
  };

  const handleDeleteBookSlot = () => {
    props.loadBooks(props.center._id);
  };

  const handleBookSlot = useCallback(
    (id, title, start, end) => {
      if (id) {
        setBookingDate((prev) => [...prev, { id, start, end, title }]);
      }
      props.loadBooks(props.center._id);
    },
    [setBookingDate]
  );

  const handleSelectBook = useCallback(
    (event) => {
      setBookingEdit(event);
      setBooking(props.bookings.find(book => book._id === event.id));
      setBookingEditVisible(true);
      // window.alert(event.title)
    },
    []
  );

  if (loading) {
    return (<Spinner/>);
  }

  function CustomToolbar () {
    return (
      <div className="toolbar-container">

        <div className="back-next-buttons">
          <button className="btn btn-back">
            {'<-'}
          </button>
          <label className='label-date'>Aug-Sept 2016</label>
        </div>

        <div className="filter-container">
          <ButtonGroup>
            <Button className="bg-filter-off"><span className="label-filter-off">Day</span></Button>
            <Button className="bg-filter-off"><span className="label-filter-off">Week</span></Button>
            <Button className="bg-filter-off"><span className="label-filter-off">Month</span></Button>
            <Button className="bg-filter-off"><span className="label-filter-off">Year</span></Button>
          </ButtonGroup>

        </div>
      </div >
    );
  }

  const getTextColor = (bgColor) => {
    let color = 'black';
    switch (bgColor) {
    case '#f5222d':
      color = 'white';
      break;
    case '#fa8c16':
      color = 'black';
      break;
    case '#fadb14':
      color = 'black';
      break;
    case '#52c41a':
      color = 'black';
      break;
    case '#1890ff':
      color = 'white';
      break;
    case '#722ed1':
      color = 'white';
      break;
    case '#eb2f96':
      color = 'white';
      break;
    case '#13c2c2':
      color = 'black';
      break;
    case '#000000':
      color = 'white';
      break;
    case '#bfbfbf':
      color = 'black';
      break;
    case '#ffffff':
      color = 'black';
      break;
    default:
      color = 'black';
      break;
    }
    return color;
  };

  return (
    <>
      <div style={{ height: 600 }}>
        <DragAndDropCalendar
          ref={calendarRef}
          dayLayoutAlgorithm={'no-overlap'}
          defaultView={isTabletOrMobile ? Views.DAY : Views.WEEK}
          date={props.selectedDay}
          events={bookingDate}
          // components={{
          //   toolbar: CustomToolbar
          // }}
          localizer={localizer}
          onEventDrop={moveBook}
          onEventResize={resizeBook}
          resizable
          resizableAccessor={() => true}
          selectable
          showMultiDayTimes={true}
          timeslots={2}
          step={15}
          onSelectEvent={handleSelectBook}
          onSelectSlot={handleSelectSlot}
          onView={(view) => { props.setCalendarView(view); }}
          views={['day', 'week', 'month']}
          culture={'es'}
          onNavigate={(date) => { props.setSelectedDay(date); }}
          scrollToTime={new Date(new Date().setHours(8, 0))}
          eventPropGetter={event => ({
            style: {
              backgroundColor: event.color,
              color: getTextColor(event.color),
              border: '3px solid',
              borderColor: event.borderColor ? event.borderColor : 'black',
              lineHeight: '1.3'
            }
          })}
          messages={
            {
              allDay: 'Todo el día',
              previous: 'Anterior',
              next: 'Siguiente',
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              agenda: 'Agenda',
              date: 'Fecha',
              time: 'Hora',
              event: 'Evento',
              showMore: total => `+ ${total} más`
            }
          }
        />
      </div>
      <BookingCreate bookingDataVisible={bookingCreateVisible} setBookingDataVisible={setBookingCreateVisible} start={startData} end={endData} handleBookSlot={handleBookSlot} trainers={props.trainers} center={props.center} user={props.user} />
      {bookingEdit && <BookingEdit bookingDataVisible={bookingEditVisible} setBookingDataVisible={setBookingEditVisible} handleEditBookSlot={handleEditBookSlot} booking={booking} handleDeleteBookSlot={handleDeleteBookSlot} edit={bookingEdit} trainers={props.trainers} center={props.center} user={props.user} />}
    </>
  );
};

export default BookingDay;
