import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Popconfirm, message, Select } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { getAuth, createUserWithEmailAndPassword, signOut, getIdToken } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { createAccount, getAccountById, patchAccount } from '../../../services/user.service';
import { getCenterByQuery } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateTrainer = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [trainer, setTrainer] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [error, seterror] = useState('');
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const colors = [{
    name: capitalize(t('red')),
    value: '#f5222d'
  }, {
    name: capitalize(t('orange')),
    value: '#fa8c16'
  }, {
    name: capitalize(t('yellow')),
    value: '#fadb14'
  }, {
    name: capitalize(t('green')),
    value: '#52c41a'
  }, {
    name: capitalize(t('blue')),
    value: '#1890ff'
  }, {
    name: capitalize(t('purple')),
    value: '#722ed1'
  }, {
    name: capitalize(t('pink')),
    value: '#eb2f96'
  }, {
    name: capitalize(t('cyan')),
    value: '#13c2c2'
  }, {
    name: capitalize(t('black')),
    value: '#000000'
  }, {
    name: capitalize(t('grey')),
    value: '#bfbfbf'
  }];

  useEffect(() => {
    if (user.account) {
      loadCenter();
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ organizer: user.account._id }, user.token);
    if (status === 200) {
      if (params.id) {
        await loadTrainer(params.id, user.token);
      }
      setCenter(data.data[0]);
      setLoading(false);
    }
  };

  const loadTrainer = async (id, token) => {
    const { status, data } = await getAccountById(id, token);
    if (status === 200) {
      setTrainer(data.data);
      fillForm(data.data);
    }
  };

  const fillForm = (trainer) => {
    form.setFieldsValue({
      email: trainer.email,
      name: trainer.name,
      surname: trainer.surname,
      phone: trainer.phone,
      nif: trainer.nif,
      color: trainer.color ? trainer.color : '#000000'
    });
  };

  const deactivateTrainer = async () => {
    if (params.id) {
      await patchAccount(params.id, { isActive: false }, user.token);
      message.success(capitalizeAll(t('trainerDeleted')));
      navigate('/dashboard/trainers');
    }
  };

  const createNewAccount = async (data) => {
    const formData = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      firebaseUid: null,
      birthday: '',
      phone: data.phone,
      roles: 'trainer',
      nif: data.nif,
      color: data.color,
      center: center._id
    };

    if (params.id) await patchAccount(params.id, formData, user.token);
    else {
      formData.password = data.passwd;
      await createAccount(formData, user.token);
    }
    navigate('/dashboard/trainers');
  };

  const correctClave = async e => {
    await createNewAccount(e);
    // if (params.id) {
    //   await createNewAccount(e, trainer.firebaseUid, user.token);
    // } else {
    //   const { email, passwd } = e;
    //   const auth = getAuth(signUpApp);
    //   await createUserWithEmailAndPassword(auth, email, passwd)
    //     .then(result => {
    //       createNewAccount(e, result.user.uid);
    //     })
    //     .catch(error => {
    //       seterror(error.message);
    //     });
    //   await signOut(auth);
    // }
  };

  if (loading) {
    return <Spinner/>;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form form={form} onFinish={correctClave} style={{
        width: '100%',
        maxWidth: 400
      }}>
        <Form.Item>
          {trainer ? <h1>{capitalizeAll(t('editTrainer'))}</h1> : <h1>{capitalizeAll(t('createTrainer'))}</h1>}
        </Form.Item>
        {error ? <Form.Item>{error}</Form.Item> : null}
        <Form.Item
          name="email"
          rules={[{
            required: true,
            message: capitalize(t('email')) + ' ' + t('isRequired')
          }]}>
          {trainer
            ? (<Input
              addonBefore={capitalize(t('email'))}
              name="email"
              defaultValue={trainer ? trainer.email : ''}
              placeholder={capitalize(t('email'))}
              disabled
            />)
            : (<Input
              addonBefore={capitalize(t('email'))}
              name="email"
              placeholder={capitalize(t('email'))}
            />)}

        </Form.Item>
        {trainer
          ? null
          : (
            <Form.Item name="passwd"
              rules={[{
                required: true,
                message: capitalize(t('password')) + ' ' + t('isRequired')
              }]}>
              {trainer
                ? (<Input
                  addonBefore={capitalize(t('password'))}
                  name="passwd"
                  type="password"
                  placeholder={capitalize(t('password'))}
                  disabled
                />)
                : (<Input
                  addonBefore={capitalize(t('password'))}
                  name="passwd"
                  type="password"
                  placeholder={capitalize(t('password'))}
                />)}

            </Form.Item>)}
        <Form.Item
          name="name"
          rules={[{
            required: true,
            message: capitalize(t('name')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('name'))}
            name="name"
            defaultValue={trainer ? trainer.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <Form.Item
          name="surname"
          rules={[{
            required: true,
            message: capitalize(t('surname')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('surname'))}
            name="surname"
            defaultValue={trainer ? trainer.surname : ''}
            placeholder={capitalize(t('surname'))}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[{
            required: true,
            message: capitalize(t('phone')) + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={capitalize(t('phone'))}
            name="phone"
            defaultValue={trainer ? trainer.phone : ''}
            placeholder={capitalize(t('phone'))}
          />
        </Form.Item>
        <Form.Item
          name="nif"
          rules={[{
            required: true,
            message: t('nif').toUpperCase() + ' ' + t('isRequired')
          }]}>
          <Input
            addonBefore={t('nif').toUpperCase()}
            name="nif"
            defaultValue={trainer ? trainer.nif : ''}
            placeholder={t('nif').toUpperCase()}
          />
        </Form.Item>
        <Form.Item
          name="color"
          rules={[{
            required: true,
            message: capitalize(t('idColor')) + ' ' + t('isRequired')
          }]}>
          <Select
            placeholder={capitalize(t('idColor'))}
            defaultValue={trainer && trainer.color ? trainer.color : '#000000'}
          >
            {colors.map((color, index) => (
              <Select.Option key={index} value={color.value} style={{ color: color.value }}>
                {color.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'space-between' }}>
          {trainer && (
            <Popconfirm
              title={capitalize(t('confirmDeleteTrainer'))}
              onConfirm={deactivateTrainer}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  className="delete-form-button"
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginRight: 10 }}
            >
              {trainer ? capitalizeAll(t('saveChanges')) : capitalizeAll(t('createTrainer'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateTrainer;
